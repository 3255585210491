<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{$t('label.CustomListsFormat')}}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <!--<CButton
                    color="danger"
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnPdf()"
                >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF
                </CButton>-->
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm" 
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>  
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"  
                    v-model="search"         
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-4 col-xl-4 text-right', input: 'col-sm-10 col-lg-8 col-xl-8 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                />
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-7 col-xl-7  text-right', input: 'col-sm-10 col-lg-5 col-xl-5 px-0'}"
                    :label="$t('label.dischargePortVisit')"
                    :options="dischargePortOptions"
                    v-model.trim="dischargePortId"
                    :value.sync="dischargePortId"
                    @change="filterDischargePort"
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <div class="d-flex bd-highlight">
                    <div class="bd-highlight" style="width:50px">
                        <CButton
                            block
                            style="height: 100vh;"
                            shape="square"
                            color="light"
                            @click="modalColumn = true"
                        >
                            <div id="vertical-orientation" class="d-flex align-items-center border-right justify-content-center">
                                <CIcon name="cil-cog" class="correct-vertical"/>
                            </div>
                        </CButton>
                    </div>
                    <div class="flex-grow-1 bd-highlight">
                        <ag-grid-vue
                            v-if="showGrid"
                            style="width: 100%; height: 100vh;"
                            class="ag-theme-alpine"
                            :gridOptions="gridOptions"
                            :defaultColDef="defaultColDef"
                            :localeText="localeText"
                            :columnDefs="columnDefs"
                            :rowData="formatedItems"
                            :suppressRowClickSelection="true"
                            :groupSelectsChildren="true"
                            :enableRangeSelection="true"
                            :pagination="true"
                            :paginationPageSize="paginationPageSize"
                            :paginationNumberFormatter="paginationNumberFormatter"
                            @grid-ready="onGridReady"
                        >
                        </ag-grid-vue>
                    </div>
                </div>
            </CCol>
        </CRow>

        <CModalExtended
            size="xl"
            color="dark"
            :closeOnBackdrop="false"
            :title="$t('label.columnConfig')"
            :show.sync="modalColumn"
            class="modal-especial-filtro"
        >   
            <CRow>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">SEAL 1</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Seal1"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">SEAL 2</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Seal2"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">SEAL 3</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Seal3"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">SEAL 4</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Seal4"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">UNIT MEASURE WEIGHT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.UnitMeasureWeight"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">IMDG CODE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ImdgCode"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">GENERAL INFO</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.GeneralInfo"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">UNIT MEASURE VOLUMEN</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.UnitMeasureVolumen"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">VOLUMEN</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Volumen"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">UNIT MEASURE RF</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.UnitMeasureRf"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">REEFER SETTING</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.ReeferSetting"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DRY REEFER</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DryReefer"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIM FRONT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DimFront"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIM BACK</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DimBack"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIM LEFT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DimLeft"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIM RIGHT</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DimRight"
                            />
                        </div>
                    </div>
                </CCol>
                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIM TOP</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DimTop"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">YARD</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Yard"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">PROCEDENCE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.Procedence"
                            />
                        </div>
                    </div>
                </CCol>

                <CCol sm="3" lg="3" xl="3">
                    <div class="form-group row">
                        <label class="col-sm-8 col-lg-8 col-xl-8 text-right">DIRECT DISCHARGE</label>
                        <div class="col-sm-4 col-lg-4 col-xl-4 d-flex align-items-center">
                            <CSwitch
                                color="watch"
                                variant="3d"
                                type="checkbox"
                                :checked.sync="columnsOption.DirectDischarge"
                            />
                        </div>
                    </div>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton outline color="wipe" @click="modalColumn=false">
                    <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            dischargePortId: '',
            unitMeasureOptions: [],
            dischargePortOptions: [],
            rows: [],
            global: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                BaySlot : true,
                BlNo : true,
                Class: true,
                ComAddressIdentifier: true,
                ComMeansTypeCode: true,
                CommodityCode: true,
                CommodityIdNumber: true,
                ContainerRemark: true,
                CtaFunctionCode: true,
                CtaIdentifier: true,
                CtaName: true,
                DangerousLabel1: true,
                DangerousLabel2: true,
                DangerousLabel3: true,
                DangerousLabelOptional: true,
                DeliveryPort: true,
                DescriptionOfGoods: true,
                DimBack: true,
                DimFront: true,
                DimLeft: true,
                DimRight: true,
                DimTop: true,
                DryReefer: true,
                EmergencySheduleNo: true,
                FlashPoint: true,
                GeneralInfo: true,
                HanDescriptionCode: true,
                HandlingRemark: true,
                HanIdentificationCode: true,
                HanInstructionDescription: true,
                HanResponsibleAgencyCode: true,
                Humidity: true,
                ImdgCode: true,
                ImdgCodePlaceCardLower: true,
                ImdgCodePlaceCardUpper: true,
                DescriptionOfGoodsDGS: true,
                MaximumRangeRf: true,
                MedicalGuide: true,
                MinimumRangeRf: true,
                OptionalPort: true,
                OriginalPortLoading: true,
                PackagingGroupCode: true,
                ReeferSetting: true,
                Seal1: true,
                Seal2: true,
                Seal3: true,
                Seal4: true,
                ShipperOwn: true,
                SpecialInstruction: true,
                TranshipmentPort: true,
                TsrIdentificationCode: true,
                TsrResponsibleAgencyCode: true,
                TsrServicePriorityCode: true,
                UnitMeasureRf: true,
                UnitMeasureVgm: true,
                UnitMeasureWeight: true,
                UnitMeasureVolumen: true,
                Volumen: true,
                UnNumber: true,
                Dua: true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            search: '',
            pageSize: '',
            labelUnitMeasure: 'TON', 
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi?.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }    
            this.unitMeasureId = listado[0].UnitMeasureId;        

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function getDischargePort () {
        this.loadingOverlay = true;
        this.dischargePortOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("StowagePlanningDischargePort-list", { StowagePlanningId: this.StowagePlanning, StowagePlanningEdiId: this.EdiFileId })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.dischargePortOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.dischargePortOptions.push({
                    value: listado[i].PortId,
                    label: listado[i].PortCode,
                })
            }    
            this.dischargePortId = this.branch.PortId;        

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(rowData.length !== 0) {
            //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
            await this.getPdf(rowData,this.$t('label.CustomListsFormat'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }   
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        if(this.formatedItems.length !== 0){
            //let un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
            this.loadingOverlay = true;
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.CustomListsFormat'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }   
        this.loadingOverlay = false;
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
        this.getDischargeList();
    }

    function filterDischargePort(e) {
        this.dischargePortId=e.target.value;
        this.getDischargeList();
    }

    async function getDischargeList () {
        this.loadingOverlay = true;

        let CargoListJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            DischargePortId: this.dischargePortId
        };

        await this.$http.post("StowagePlanningCargoDischargePort-list", CargoListJson, { root: 'CargoListJson' })
        .then(response => {
            let listado = [...response.data.data];
            let nulo = String(listado[0].Json);
            this.global = listado;
            this.rows = [];
            let y = 0;
            if (nulo=="null") return false;

            for (let i=0; i < listado.length; i++) {
                let vgm = listado[i].CargoJson[0].VgmJson.filter(ob => ob.UnitMeasureId == this.unitMeasureId)[0].Value;
                let weig = listado[i].CargoJson[0].WeigthJson.filter(ob => ob.UnitMeasureId == this.unitMeasureId)[0].Value;
                let unitWeig = listado[i].CargoJson[0].WeigthJson.filter(ob => ob.UnitMeasureId == this.unitMeasureId)[0].UnitMeasureAbbrev;
                y++;
                this.rows.push({
                    IdX: y,
                    BaySlot: listado[i].CargoJson[0].BaySlot,
                    BlNo: listado[i].CargoJson[0].BlNo,
                    BookingNumber: listado[i].CargoJson[0].BookingNumber,
                    TpCargoDetailCode: listado[i].CargoJson[0].TpCargoDetailCode,
                    Class: listado[i].CargoJson[0].Class,
                    ComAddressIdentifier: listado[i].CargoJson[0].ComAddressIdentifier,
                    ComMeansTypeCode: listado[i].CargoJson[0].ComMeansTypeCode,
                    CommodityCode: listado[i].CargoJson[0].CommodityCode,
                    CommodityIdNumber: listado[i].CargoJson[0].CommodityIdNumber,
                    Consignee: listado[i].CargoJson[0].Consignee,
                    ContainerCode: listado[i].ContainerCode,
                    ContainerRemark: listado[i].CargoJson[0].ContainerRemark,
                    CtaFunctionCode: listado[i].CargoJson[0].CtaFunctionCode,
                    CtaIdentifier: listado[i].CargoJson[0].CtaIdentifier,
                    CtaName: listado[i].CargoJson[0].CtaName,
                    DangerousLabel1: listado[i].CargoJson[0].DangerousLabel1,
                    DangerousLabel2: listado[i].CargoJson[0].DangerousLabel2,
                    DangerousLabel3: listado[i].CargoJson[0].DangerousLabel3,
                    DangerousLabelOptional: listado[i].CargoJson[0].DangerousLabelOptional,
                    DeliveryPort: listado[i].CargoJson[0].DeliveryPort,
                    DescriptionOfGoods: listado[i].CargoJson[0].DescriptionOfGoods,
                    DimBack: listado[i].CargoJson[0].DimBack,
                    DimFront: listado[i].CargoJson[0].DimFront,
                    DimLeft: listado[i].CargoJson[0].DimLeft,
                    DimRight: listado[i].CargoJson[0].DimRight,
                    DimTop: listado[i].CargoJson[0].DimTop,
                    DischargePort: listado[i].CargoJson[0].DischargePort,
                    DryReefer: listado[i].CargoJson[0].DryReefer ? this.$t('label.yes') : 'NO',
                    EmergencySheduleNo: listado[i].CargoJson[0].EmergencySheduleNo,
                    FlashPoint: listado[i].CargoJson[0].FlashPoint,
                    GeneralInfo: listado[i].CargoJson[0].GeneralInfo,
                    HanDescriptionCode: listado[i].CargoJson[0].HanDescriptionCode,
                    HandlingRemark: listado[i].CargoJson[0].HandlingRemark,
                    HanIdentificationCode: listado[i].CargoJson[0].HanIdentificationCode,
                    HanInstructionDescription: listado[i].CargoJson[0].HanInstructionDescription,
                    HanResponsibleAgencyCode: listado[i].CargoJson[0].HanResponsibleAgencyCode,
                    Humidity: listado[i].CargoJson[0].Humidity,
                    ImdgCode: listado[i].CargoJson[0].ImdgCode,
                    ImdgCodePlaceCardLower: listado[i].CargoJson[0].ImdgCodePlaceCardLower,
                    ImdgCodePlaceCardUpper: listado[i].CargoJson[0].ImdgCodePlaceCardUpper,
                    DescriptionOfGoodsDGS: listado[i].CargoJson[0].DescriptionOfGoodsDGS,
                    IsoCode: listado[i].CargoJson[0].IsoCode,
                    LoadPort: listado[i].CargoJson[0].LoadPort,
                    MaximumRangeRf: listado[i].CargoJson[0].MaximumRangeRf,
                    MedicalGuide: listado[i].CargoJson[0].MedicalGuide,
                    MinimumRangeRf: listado[i].CargoJson[0].MinimumRangeRf,
                    OptionalPort: listado[i].CargoJson[0].OptionalPort,
                    OriginalPortLoading: listado[i].CargoJson[0].OriginalPortLoading,
                    PackagingGroupCode: listado[i].CargoJson[0].PackagingGroupCode,
                    ReeferSetting: listado[i].CargoJson[0].ReeferSetting,
                    Seal1: listado[i].CargoJson[0].Seal1,
                    Seal2: listado[i].CargoJson[0].Seal2,
                    Seal3: listado[i].CargoJson[0].Seal3,
                    Seal4: listado[i].CargoJson[0].Seal4,
                    Shipper: listado[i].CargoJson[0].Shipper,
                    ShipperOwn: listado[i].CargoJson[0].ShipperOwn,
                    ShippingLineCode: listado[i].CargoJson[0].ShippingLineCode,
                    Size: listado[i].CargoJson[0].Size,
                    SpecialInstruction: listado[i].CargoJson[0].SpecialInstruction,
                    Status: listado[i].CargoJson[0].Status,
                    TranshipmentPort: listado[i].CargoJson[0].TranshipmentPort,
                    TsrIdentificationCode: listado[i].CargoJson[0].TsrIdentificationCode,
                    TsrResponsibleAgencyCode: listado[i].CargoJson[0].TsrResponsibleAgencyCode,
                    TsrServicePriorityCode: listado[i].CargoJson[0].TsrServicePriorityCode,
                    UnitMeasureRf: listado[i].CargoJson[0].UnitMeasureRf,
                    UnitMeasureVgm: listado[i].CargoJson[0].UnitMeasureVgm,
                    UnitMeasureWeight: unitWeig,
                    UnitMeasureVolumen: listado[i].CargoJson[0].UnitMeasureVolumenAbbrev,
                    Volumen: listado[i].CargoJson[0].Volumen,
                    UnNumber: listado[i].CargoJson[0].UnNumber,
                    Vgm: vgm,
                    VgmCertificate: listado[i].CargoJson[0].VgmCertificate,
                    Weigth: weig,
                    Dua: listado[i].CargoJson[0].Dua,
                    Procedence: listado[i].Procedence,
                    YardNamePlanning: listado[i].YardNamePlanning, 
                    FgDirect: listado[i].FgDirect
                })
            }
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-info text-white font-weight-bold'
        }
    }

    function columnDefs(){
        let columnDefs = [
            {
                field: "IdX",
                headerName: "#",
                //lockVisible: true,
                headerClass: 'center-cell',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                //pinned: 'left',
                cellClass: 'center-cell-especial gb-cell',
                filter: 'agTextColumnFilter',
                //checkboxSelection: true, 
            },
        ]
        columnDefs.push({
            field: "ContainerCode",
            headerName: "CONTAINER",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: false,
            minWidth: 160,
            //pinned: 'left',
            cellClass: 'center-cell-especial gb-cell',
        });
        columnDefs.push({
            field: "TpCargoDetailCode",
            headerName: "SIZE (FT)",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: false,
            minWidth: 160,
            //pinned: 'left',
            cellClass: 'center-cell-especial gb-cell',
        });
        columnDefs.push({
            field: "IsoCode",
            headerName: "ISO CODE",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 160,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
        });
        if(this.columnsOption.Seal1){
            columnDefs.push({
                field: "Seal1",
                headerName: "SEAL 1",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Seal2){
            columnDefs.push({
                field: "Seal2",
                headerName: "SEAL 2",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Seal3){
            columnDefs.push({
                field: "Seal3",
                headerName: "SEAL 3",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Seal4){
            columnDefs.push({
                field: "Seal4",
                headerName: "SEAL 4",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.UnitMeasureWeight){
            columnDefs.push({
                field: "UnitMeasureWeight",
                headerName: "UNIT MEASURE WEIGHT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        columnDefs.push({
            field: "Weigth",
            headerName: "WEIGHT",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 150,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
            cellRenderer: params => {
                let variable = 0.00;
                if(params.value !== null && params.value !== '' ){    
                    let newValue =  Number.parseFloat(params.value).toFixed(2);
                    variable = formatMilDecimal(newValue);
                }
                return formatMilDecimal(variable);
            },
        });
        columnDefs.push({
            field: "Status",
            headerName: "STATUS",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 150,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
        });
        columnDefs.push({
            field: "DischargePort",
            headerName: "DISCHARGE PORT",
            filter: 'agTextColumnFilter',
            headerClass: 'center-cell-especial',
            suppressMovable: true,
            lockPosition: false,
            minWidth: 160,
            //pinned: 'left',
            cellClass: 'center-cell-especial gb-cell',
        });
        columnDefs.push({
            field: "LoadPort",
            headerName: "LOAD PORT",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 160,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
        });
        columnDefs.push({
            field: "BlNo",
            headerName: "BL No",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 150,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
        });
        columnDefs.push({
            field: "ShippingLineCode",
            headerName: "CARRIER CODE",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 160,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
        });
        if(this.columnsOption.ImdgCode){
            columnDefs.push({
                field: "ImdgCode",
                headerName: "IMDG CODE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        columnDefs.push({
            field: "Consignee",
            headerName: "CONSIGNEE",
            headerClass: 'center-cell-especial',
            cellClass: 'center-cell-especial gb-cell',
            minWidth: 160,
            filter: "agTextColumnFilter",
            suppressMovable: true,
            lockPosition: false,
        });
        if(this.columnsOption.GeneralInfo){
            columnDefs.push({
                field: "GeneralInfo",
                headerName: "GENERAL INFO",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.UnitMeasureVolumen){
            columnDefs.push({
                field: "UnitMeasureVolumen",
                headerName: "UNIT MEASURE VOLUMEN",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Volumen){
            columnDefs.push({
                field: "Volumen",
                headerName: "VOLUMEN",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            });
        }
        if(this.columnsOption.UnitMeasureRf){
            columnDefs.push({
                field: "UnitMeasureRf",
                headerName: "UNIT MEASURE RF",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.ReeferSetting){
            columnDefs.push({
                field: "ReeferSetting",
                headerName: "REEFER SETTING",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DryReefer){
            columnDefs.push({
                field: "DryReefer",
                headerName: "DRY REEFER",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DimFront){
            columnDefs.push({
                field: "DimFront",
                headerName: "DIM FRONT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DimBack){
            columnDefs.push({
                field: "DimBack",
                headerName: "DIM BACK",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DimLeft){
            columnDefs.push({
                field: "DimLeft",
                headerName: "DIM LEFT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DimRight){
            columnDefs.push({
                field: "DimRight",
                headerName: "DIM RIGHT",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.DimTop){
            columnDefs.push({
                field: "DimTop",
                headerName: "DIM TOP",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        if(this.columnsOption.Yard){
            columnDefs.push({
                field: "YardNamePlanning",
                headerName: "YARD",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.Procedence){
            columnDefs.push({
                field: "Procedence",
                headerName: "PROCEDENCE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }

        if(this.columnsOption.DirectDischarge){
            columnDefs.push({
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE",
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                minWidth: 150,
                filter: "agTextColumnFilter",
            });
        }
        return columnDefs;
    }

    function formatedItems() {
        let computedItems = [];
        
        if(this.rows.length !== 0){
            this.rows.map((item) => {
                computedItems.push({
                    IdX: item.IdX,
                    BaySlot: item.BaySlot ? item.BaySlot : '',
                    BookingNumber: item.BookingNumber ? item.BookingNumber : '',
                    TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    Class: item.Class ? item.Class : '',
                    ComAddressIdentifier: item.ComAddressIdentifier ? item.ComAddressIdentifier : '',
                    ComMeansTypeCode: item.ComMeansTypeCode ? item.ComMeansTypeCode : '',
                    CommodityCode: item.CommodityCode ? item.CommodityCode : '',
                    CommodityIdNumber: item.CommodityIdNumber ? item.CommodityIdNumber : '',
                    Consignee: item.Consignee ? item.Consignee : '',
                    ContainerCode: item.ContainerCode ? item.ContainerCode : '',
                    ContainerRemark: item.ContainerRemark ? item.ContainerRemark : '',
                    CtaFunctionCode: item.CtaFunctionCode ? item.CtaFunctionCode : '',
                    CtaIdentifier: item.CtaIdentifier ? item.CtaIdentifier : '',
                    CtaName: item.CtaName ? item.CtaName : '',
                    DangerousLabel1: item.DangerousLabel1 ? item.DangerousLabel1 : '',
                    DangerousLabel2: item.DangerousLabel2 ? item.DangerousLabel2 : '',
                    DangerousLabel3: item.DangerousLabel3 ? item.DangerousLabel3 : '',
                    DangerousLabelOptional: item.DangerousLabelOptional ? item.DangerousLabelOptional : '',
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                    DescriptionOfGoods: item.DescriptionOfGoods ? item.DescriptionOfGoods : '',
                    DimBack: item.DimBack ? item.DimBack : '',
                    DimFront: item.DimFront ? item.DimFront : '',
                    DimLeft: item.DimLeft ? item.DimLeft : '',
                    DimRight: item.DimRight ? item.DimRight : '',
                    DimTop: item.DimTop ? item.DimTop : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    DryReefer: item.DryReefer ? item.DryReefer : '',
                    EmergencySheduleNo: item.EmergencySheduleNo ? item.EmergencySheduleNo : '',
                    FlashPoint: item.FlashPoint ? item.FlashPoint : '',
                    GeneralInfo: item.GeneralInfo ? item.GeneralInfo : '',
                    HanDescriptionCode: item.HanDescriptionCode ? item.HanDescriptionCode : '',
                    HandlingRemark: item.HandlingRemark ? item.HandlingRemark : '',
                    HanIdentificationCode: item.HanIdentificationCode ? item.HanIdentificationCode : '',
                    HanInstructionDescription: item.HanInstructionDescription ? item.HanInstructionDescription : '',
                    HanResponsibleAgencyCode: item.HanResponsibleAgencyCode ? item.HanResponsibleAgencyCode : '',
                    Humidity: item.Humidity ? item.Humidity : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    ImdgCodePlaceCardLower: item.ImdgCodePlaceCardLower ? item.ImdgCodePlaceCardLower : '',
                    ImdgCodePlaceCardUpper: item.ImdgCodePlaceCardUpper ? item.ImdgCodePlaceCardUpper : '',
                    DescriptionOfGoodsDGS: item.DescriptionOfGoodsDGS ? item.DescriptionOfGoodsDGS : '',
                    IsoCode: item.IsoCode ? item.IsoCode : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    MaximumRangeRf: item.MaximumRangeRf ? item.MaximumRangeRf : '',
                    MedicalGuide: item.MedicalGuide ? item.MedicalGuide : '',
                    MinimumRangeRf: item.MinimumRangeRf ? item.MinimumRangeRf : '',
                    OptionalPort: item.OptionalPort ? item.OptionalPort : '',
                    OriginalPortLoading: item.OriginalPortLoading ? item.OriginalPortLoading : '',
                    PackagingGroupCode: item.PackagingGroupCode ? item.PackagingGroupCode : '',
                    ReeferSetting: item.ReeferSetting ? item.ReeferSetting : '',
                    Seal1: item.Seal1 ? item.Seal1 : '',
                    Seal2: item.Seal2 ? item.Seal2 : '',
                    Seal3: item.Seal3 ? item.Seal3 : '',
                    Seal4: item.Seal4 ? item.Seal4 : '',
                    Shipper: item.Shipper ? item.Shipper : '',
                    ShipperOwn: item.ShipperOwn ? item.ShipperOwn : '',
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    Size: item.Size ? item.Size : '',
                    SpecialInstruction: item.SpecialInstruction ? item.SpecialInstruction : '',
                    Status: item.Status ? item.Status : '',
                    TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                    TsrIdentificationCode: item.TsrIdentificationCode ? item.TsrIdentificationCode : '',
                    TsrResponsibleAgencyCode: item.TsrResponsibleAgencyCode ? item.TsrResponsibleAgencyCode : '',
                    TsrServicePriorityCode: item.TsrServicePriorityCode ? item.TsrServicePriorityCode : '',
                    UnitMeasureRf: item.UnitMeasureRf ? item.UnitMeasureRf : '',
                    UnitMeasureVgm: item.UnitMeasureVgm ? item.UnitMeasureVgm : '',
                    UnitMeasureWeight: item.UnitMeasureWeight ? item.UnitMeasureWeight : '',
                    UnitMeasureVolumen: item.UnitMeasureVolumen ? item.UnitMeasureVolumen : '',
                    Volumen: item.Volumen,
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    Vgm: item.Vgm,
                    VgmCertificate: item.VgmCertificate ? item.VgmCertificate : '',
                    Weigth: item.Weigth,
                    Dua: item.Dua ? item.Dua : '',
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        
        return computedItems;
    }

    export default {
        name: "custom-list-format",
        data,
        beforeMount,
        mounted,
        mixins: [Reportes, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getDischargeList,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            filterDischargePort,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            getDischargePort
        },
        computed:{
            columnDefs,
            formatedItems,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem,
                user: state => state.auth.user,
                branch: (state) => state.auth.branch,
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==25) {
                    this.pageSize='';
                    this.unitMeasureId= '';
                    this.dischargePortId = '';
                    this.search="";
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getDischargePort();
                    await this.getDischargeList();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style lang="scss" >
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }

    .modal-especial-filtro {
        .modal-xl {
            min-width: 88%;
        }
    }
</style>